<template>
  <v-data-table
    v-model="localModel"
    v-bind="tableConfig"
    v-on="tableConfig.on"
    :page.sync="page"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading="loading"
    :hide-default-footer="items.length === 0 || tableConfig.hideDefaultFooter || !totalPages"
    :loading-text="loadingMessage"
    :server-items-length="totalPages"
    class="box-shadow"
    :height="!!items && items.length >= 10 ? 528 : tableConfig.height"
    @update:options="changePage($event)"
    :footer-props="{
      itemsPerPageOptions: itemsPerPageOptions,
      disablePagination: loading,
    }">
    <template v-slot:no-data>
      <slot name="no-data"></slot>
    </template>

    <template v-for="header in tableConfig.headers" v-slot:[`item.${header.value}`]="slotProps">
      <slot :name="`item.${header.value}`" v-bind="slotProps"></slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DefaultTable",

  data: () => ({
    page: 1,
    itemsPerPage: 25,
    localModel: [],
    sort: null,
  }),

  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [ 25, 50, 75, 100 ],
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando..."
    },
    totalPages: {
      type: Number,
      required: false,
    },

    model: {
      required: false,
    }
  },

  watch: {
    model: {
      handler(model) {
        this.localModel = model;
      },
    },

    localModel: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal) return;

        const differentLength = oldVal && newVal.length !== oldVal.length;
        const fieldsValidation = !newVal.every(
          (value, index) => value === oldVal[index]);

        if (differentLength || fieldsValidation) {
          this.$emit('updateModel', [...newVal]);
        }
      },
    },

    itemsPerPage: {
      immediate: true,
      handler(newVal) {
        this.$emit('itemsPerPage', newVal);
      }
    },

    page: {
      immediate: true,
      handler(newVal) {
        this.$emit('page', newVal);
      }
    },

    sort: {
      immediate: true,
      handler(newVal) {
        this.$emit('sort', newVal);
      }
    },
  },

  methods: {
    changePage(event) {
      const { page: newPage, itemsPerPage, sortBy, sortDesc } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      this.sort = `${sortBy[0] ? sortBy[0] : 'id'},${sortDesc[0] ? 'asc' : 'desc'}`;

      setTimeout(() => this.$emit('search'), 200);
    },
  },
}
</script>
