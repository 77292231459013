<template>
  <v-expansion-panels
    :class="{
			'box-shadow': true,
		}"
		v-model="filterExpanded">
    <v-expansion-panel>
      <v-container
        fluid>
        <v-row>
          <v-col
            v-bind="leftSideColProps"
            class="d-flex align-center">
            <span
              :class="`text-h5 font-weight-bold ${defaultColor}--text`">
              Filtro
            </span>
          </v-col>

          <v-col
            v-bind="mainColProps"
            class="pb-0">
            <v-form
              ref="DefaultFilter"
              v-if="!showSaveSearch">
              <v-row>
                <v-col
                  v-bind="defaultColsProps"
                  v-if="showRangeFilter">
                  <v-select
                    prepend-inner-icon="fas fa-calendar-alt"
                    v-model="selectedDate"
                    :items="dateItems"
                    v-bind="fieldFilterProps"
                  />
                </v-col>
                <v-col
                  v-bind="defaultColsProps"
                  v-if="selectedDate === 'custom'">
                  <v-menu
                    max-width="350px"
                    v-model="menuDateCustom"
                    ref="menuDateCustom"
                    transition="scale-transition"
                    offset-y
                    :close-on-content-click="false"
                    :return-value.sync="dates">
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="fieldFilterProps"
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      width="auto"
                      range
                      v-model="dates"
                      no-title>
                      <v-row
                        class="mx-2">
                        <v-col
                          cols="12"
                          class="d-flex align-end">
                          <v-btn
                            text
                            :color="defaultColor"
                            @click="menuDateCustom = false">
                            Cancelar
                          </v-btn>

                          <v-btn
                            text
                            :color="defaultColor"
                            @click="handlerDate(dates)">
                            OK
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  v-for="filter in
                    !showRangeFilter
                      ? [...filters].slice(0, 3)
                      : [...filters].slice(0, 2)"
                  :key="filter.key"
                  v-bind="'col' in filter ? filter.col.attrs : null"
                  :class="{
                    ...filter.attrs.class,
                    'd-flex align-center justify-center': true,
                  }">
                  <DefaultComponent
                    :field="filter"
                    :model="model"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col
            v-if="$vuetify.breakpoint.lgAndUp"
            v-bind="rightSideColProps"
            :class="{
              'd-flex': true,
              'justify-end': $vuetify.breakpoint.lgAndUp,
              'flex-column pb-0': $vuetify.breakpoint.smAndDown
            }">
            <FilterActions
              :syncIconTransition="syncIconTransition"
              :loading="loading"
              :defaultColor="defaultColor"
              :defaultSearchLabel="defaultSearchLabel"
              :defaultClearLabel="defaultClearLabel"
              :showExpansion="showExpansion"
              @expand="expand"
              @search="search"
              @clearFilters="clearFilters"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-expansion-panel-content
        :class="{
          'defaultFilterContent': $vuetify.breakpoint.lgAndUp,
          'defaultFilterContentMd': !$vuetify.breakpoint.lgAndUp
        }">
        <v-container
            fluid>
            <v-row>
              <v-col
                v-if="$vuetify.breakpoint.lgAndUp"
                v-bind="leftSideColProps">
                <div
                  v-if="useSaveFilter"
                  class="d-flex flex-row align-center"
                  @click="showSaveSearch = !showSaveSearch">
                  <span
                    :class="`text-subtitle-1 font-weight-bold ${defaultColor}--text`"
                    style="cursor: pointer;">
                    {{ !showSaveSearch ? 'Pesquisas Salvas' : 'Ocultar' }}
                  </span>
                  <v-icon
                    dense
                    class="ml-2"
                    v-if="!showSaveSearch"
                    :color="defaultColor">
                    far fa-bookmark
                  </v-icon>
                </div>
              </v-col>

              <v-col
                v-bind="mainColProps"
                class="pt-0">
                <v-row>
                  <v-col
                    v-if="!showSaveSearch"
                    v-for="
                      filter in !showRangeFilter
                        ? [...filters].slice(3, filters.length)
                        : [...filters].slice(2, filters.length)"
                    :key="filter.key"
                    v-bind="'col' in filter ? filter.col.attrs : null"
                    :class="{
                      ...filter.attrs.class,
                      'd-flex align-center justify-center': true,
                    }">
                    <DefaultComponent
                      :field="filter"
                      :model="model"
                    />
                  </v-col>
                  <FavoriteSearchComponent
                    v-if="showSaveSearch"
                    filterBy="DefaultFilter"
                    :searchFilter="model"
                    :changeTabValue="changeTabValue"
                    @loadThisFilter="$emit('loadSavedFilter', $event)"
                  />
                </v-row>
              </v-col>

              <v-col
                v-if="$vuetify.breakpoint.lgAndUp"
                v-bind="rightSideColProps"
              />
            </v-row>
          </v-container>
      </v-expansion-panel-content>

      <div
        :class="{
          'my-2': $vuetify.breakpoint.mdAndDown
        }">
        <FilterActions
          v-if="$vuetify.breakpoint.mdAndDown"
          :syncIconTransition="syncIconTransition"
          :loading="loading"
          :defaultColor="defaultColor"
          :defaultSearchLabel="defaultSearchLabel"
          :defaultClearLabel="defaultClearLabel"
          :showExpansion="showExpansion"
          @expand="expand"
          @search="search"
          @clearFilters="clearFilters"
        />
      </div>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';
import DefaultComponent from '../DefaultComponent/DefaultComponent.vue';
import FilterActions from './FilterActions.vue';
import moment from 'moment';

export default {
  name: "DefaultFilter",

  components: {
    FavoriteSearchComponent,
    DefaultComponent,
    FilterActions,
  },

  data: () => ({
    filterExpanded: null,
    showSaveSearch: false,
    moment: moment,
    menuDateCustom: false,
    datesSearch: null,
    dates: [],
    fieldFilterProps: {
      outlined: true,
      dense: true,
      hideDetails: true,
      clearable: false,
      placeholder: "Filtrar data",
    },
    selectedDate: 'lastThirtyDays',
    dateType: ['createdAt'],
    dateItems: [
      {
        text: 'Últimos 7 dias',
        value: 'lastSevenDays',
        disabled: false,
      },
      {
        text: 'Últimos 30 dias',
        value: 'lastThirtyDays',
        disabled: false,
      },
      {
        text: 'Últimos 3 meses',
        value: 'lastThreeMonths',
        disabled: false,
      },
      {
        text: 'Mês Atual',
        value: 'currentMonth',
        disabled: false,
      },
      {
        text: 'Nenhuma Seleção de Data',
        value: 'noDate',
        disabled: false,
      },
      {
        text: 'Personalizado',
        value: 'custom',
        disabled: false,
      },
    ],
  }),

  props: {
    filters: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    useSaveFilter: {
      type: Boolean,
      default: false,
    },
    showExpansion: {
      type: Boolean,
      default: true,
    },
    showRangeFilter: {
      type: Boolean,
      default: true,
    },
    model: {
      type: Object,
      required: true,
    },
    refFilter: {
      type: String,
      required: true,
      default: 'filter'
    },
    defaultColor: {
      type: String,
      default: 'primary'
    },
    defaultColsProps: {
      type: Object,
      default: () => ({
        cols: 12,
        xl: 4,
        lg: 4,
        md: 4,
        sm: 12,
      })
    },
    mainColProps: {
      type: Object,
      default: () => ({
        xl: 7,
        lg: 6,
        md: 12,
        sm: 12,
        cols: 12,
      })
    },
    leftSideColProps: {
      type: Object,
      default: () => ({
        xl: 2,
        lg: 2,
        md: 12,
        sm: 12,
        cols: 12,
      })
    },
    rightSideColProps: {
      type: Object,
      default: () => ({
        xl: 3,
        lg: 4,
        md: 12,
        sm: 12,
        cols: 12,
      })
    },
    defaultSearchLabel: {
      type: String,
      default: "Pesquisar"
    },
    defaultClearLabel: {
      type: String,
      default: "Limpar Filtros"
    },
    changeTabValue: {
      type: String,
      default: "MOVEMENT"
    },
  },

  watch: {
    selectedDate: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const dateValue = newValue || 'lastThirtyDays';

        this.setDateFilter(dateValue);

        this.setDates();
      },
    },
  },

  methods: {
    search() {
      if (!this.$refs.DefaultFilter.validate()) return;
      this.$emit('search');
    },

    setDateFilter(value) {
      const today = moment();
      const dateRanges = {
        lastSevenDays: 6,
        lastThirtyDays: 29,
        lastThreeMonths: 89,
        currentMonth: today.clone().startOf('day').diff(today.clone().startOf('month').startOf('day'), 'days'),
        noDate: 'noDate',
      };

      let startDate;
      if (value === 'noDate') {
        this.dates = [];
        startDate = [];
      } else if (dateRanges[value] !== undefined) {
        startDate = today.clone().subtract(dateRanges[value], 'days');
        this.dates = [startDate.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
      }

      this.setDataCustom(this.dates);
    },

    getInput(type) {
      switch (type) {
        case 'select':
          return VSelect;
        case 'combobox':
          return VCombobox;
        case 'textfield':
          return VTextField;
        case 'autocomplete':
          return VAutocomplete;
        case 'checkbox':
          return VCheckbox;
        case 'radio':
          return VRadio;
        case 'datepicker':
          return VDatePicker;
        case 'switch':
          return VSwitch;
        default:
          return VTextField;
      }
    },

    handlerDate(dates) {
			this.datesSearch = null;
      this.menuDateCustom = false;
			const dateFormated = dates;

      if (dateFormated[0] > dateFormated[1]) {
        [dateFormated[0], dateFormated[1]] = [dateFormated[1], dateFormated[0]];
      }

      this.$refs.menuDateCustom.save(dateFormated);

      this.setDataCustom(dateFormated);

      this.setDates();
    },

    setDataCustom(dates) {
      if (this.selectedDate === 'custom') {
        const cleanedDates = dates.map((date) => {
          if (date.includes('T00:00:00.000')) {
            date = date.replaceAll('T00:00:00.000', '');
          }
          if (date.includes('T23:59:59.999')) {
            date = date.replaceAll('T23:59:59.999', '');
          }
          return date;
        });

        const datesSearch = {
          dateType: this.dateType,
          dates: cleanedDates,
        };

				this.datesSearch = datesSearch;
      }
    },

    clearFilters() {
      Object.keys(this.model).forEach(key => {
        if (key === 'dates') {
          this.setDateFilter('lastThirtyDays');

          this.selectedDate = 'lastThirtyDays';

          this.setDates();

        } else {
          this.$set(this.model, key, null);
        }
      });
    },

    expand() {
			if (this.filterExpanded === 0) {
				this.filterExpanded = undefined
			} else {
				this.filterExpanded = 0
			}
		},

    setDates() {
      if (!this.showRangeFilter) return;

      this.$set(this.model, 'dates', !!this.dates ? this.dates : []);
    }
  },

  computed: {
    dateRangeText() {
      let text = '-';

      if (this.dates && this.dates.length > 0) {
        const sortedDates = this.dates.slice().sort((a, b) => new Date(a) - new Date(b));

        const formattedStartDate = moment(sortedDates[0]).format('DD/MM/YYYY');

        const formattedEndDate = moment(sortedDates[1]).format('DD/MM/YYYY');

        text = `${formattedStartDate} ~ ${formattedEndDate}`;
      }

      return text;
    },

    syncIconTransition() {
			return {
        transform: this.filterExpanded !== 0 ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.25s',
      };
		},
  }
};
</script>

<style>
.defaultFilterContent > .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.defaultFilterContentMd > .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

<style scoped>
.onClickEffect {
	background-color: transparent !important;
}

.onClickEffect:after {
	background-color: transparent !important;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>